<section class="assetsearch">
    <app-domain-table [searchTextValues]='searchTxt' [showGenericMessage]='showGenericMessage'
        [errorValue]='errorValue' [parentName]="'taggingTargetMessage'" [showingArr]='showingArr' [outerArr]='outerArr'
        [searchableHeader]='true' [allColumns]='allColumns' [dataHead]="'All Asset Groups'"
        (selectedRow)='goToDetails($event)' [firstPaginator]='firstPaginator' [lastPaginator]='lastPaginator'
        [totalRows]='totalRows' [totalPages]='totalPages' [pageNumber]='pageNumber' [currentPointer]='currentPointer' (searchRowTxt)='searchCalled($event)'
        (previousPageCalled)='prevPage()' (nextPageCalled)='nextPage()' (searchTriggerred)='callNewSearch()'
        [assetGroupTableHeader]='assetGroupTableHeader'>
    </app-domain-table>
    <div class="data-table-desc">{{dataTableDesc}}</div>
</section>