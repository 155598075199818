import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'compliance',
    redirectTo: '/compliance',
    pathMatch: 'full'
  },
  // {
  //   path: 'post-login',
  //   redirectTo: '/home',
  //   pathMatch: 'prefix'
  // }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes)],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
