<div >
    <!-- <img src="../../../../assets/images/brick.PNG" width="46px"> <br> <b>{{awsResourceDetails.name}}</b> <br> -->
    <img src={{imagePath}} width="46px" height="46px"> <br> <b>{{awsResourceDetails.name}}</b> <br>


    <div class="status-bar">
        <div class="bar" [ngStyle]="{'width': tagged}"><div class="tagged"></div></div>
        <div class="bar untagged" [ngStyle]="{'width': untagged}"></div>
    </div>
    
    <div class="row">
        <!-- (click)="awsResourceDetails.tagged > 0 && instructParentToNavigate(awsResourceDetails.name,'tagged')" -->
        <!-- (click)="awsResourceDetails.untagged > 0 &&  instructParentToNavigate(awsResourceDetails.name,'untagged')" -->
        <div class="col-6"><b>{{awsResourceDetails.tagged}}</b><br>Tagged</div>
        <div class="col-6"><b>{{awsResourceDetails.untagged}}</b><br>Untagged</div>
    </div>
</div>