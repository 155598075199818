
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { KeycloakService } from 'src/app/core/services/keycloak.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
  providers: []
})
export class UserInfoComponent implements OnInit {
  keycloakservice: any;

  constructor(private authenticateService: AuthService,  private keyCloakService: KeycloakService,) { }

  @Input() firstName: string ='';

  @Output() closeInfoPop = new EventEmitter();

  ngOnInit() { }

  logout() {
    this.keyCloakService.logout();
  }
}
