import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import * as moment from 'moment';
import { NgxSelectComponent } from 'ngx-select-ex';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { GrcService } from 'src/app/pacman-features/services/grc.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-risk',
  templateUrl: './update-risk.component.html',
  styleUrls: ['./update-risk.component.css']
})
export class UpdateRiskComponent implements OnInit {
  @ViewChild('targetType') targetTypeSelectComponent: NgxSelectComponent | any;
  @ViewChild('targetTypeRuleSelect') targetTypeRuleSelectComponent: NgxSelectComponent | any;
  ruleLoader = false;
  @Input() label?: string; // Optional label for the select
  @Output() selectedValueChange1 = new EventEmitter<number>();
  breadcrumbArray: any = ['GRC', 'Risk-Management'];
  breadcrumbLinks: any = ['Risk-Management', 'Create-Risk'];
  breadcrumbPresent: any = 'Create Risk Register';
  // showingArr = ['affectedFunctions'];
  affectedFunctions: any = [];
  riskCategory:any=[];
  riskProbability:any=[];
  riskImpact:any=[];
  riskValue:any=[];
  riskId:any=[];
  riskTreatment:any=[];
  status:any=[];
  mitigationstatus:any=[];
  riskOwner:any=[];
  riskMitigationStatus:any=[];
  residualRiskLikelihood:any=[];
  residualImpact:any=[];
  residualRiskRating:any=[];
  riskDescription="";
  mitigationPlan="";
  department="";
  service="";
  remarks="";
  isRiskValid:boolean=false;
  errorMessage: any;
  options = [1, 2, 3, 4, 5];
  options1 = [1, 2, 3, 4, 5];
  options2 = [1, 2, 3, 4, 5];
  statusvalues = ['open','closed'];
  statusvalues2 = ['Open','Closed'];
  selectedValue: number | undefined;
  selectedValue1: number | undefined;
  selectedValue2:number| undefined;
  selectedValue3:number| undefined;
  selectedValue4:number| undefined;
  selectedValue5:number| undefined;
  selectedValue6:number| undefined;
  selectedValue7:number| undefined;
  selectedValue8:number| undefined;
  product: number| undefined;
  isRiskCreationFailed:boolean=false;
  riskLoader:boolean=false;
  isRiskCreationSuccess:boolean=false;
  successTitle: String = '';
  failedTitle: string = '';
  successSubTitle: String = '';
  loadingContent: string = '';
  highlightName: string = '';
  hideContent: boolean = false;
  isCreate: boolean = false;
  date = new Date();
  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  urlToRedirect: any = '';
  mandatory: any;
  filterText: any = {};
  errorValue: number = 0;
  urlID: String = '';
  ruleId: String = '';
  dateToday = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate();
  plannedResolutionDate: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  actualDateOfResolution: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  dateOfRisk: any = { //date
    name: '',
    reason: '',
    expiry: this.dateToday
  }
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',    
  };
  today = new Date();
   
  myDpOptions2: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',
    disableSince: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate() + 1,
    },
    disableUntil: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate() - 1,
    },
    
  };

  riskTreatmentValue: boolean = false;
  riskIdForEdit: any;
  pageTitle:any;
  riskDateValue: any;
  affectedValue:any;
  riskReferenceIdValue: any;
  departmentValue: any;
  serviceValue: any;
  categoryValue: any;
  riskProbabilityValue: any;
  riskImpactValue:any;
  riskTotal:any;
  riskDescriptionValue:any;
  riskTreatmentText: any;
  statusValue: any;
  mitigationPlanValue:any;
  ownerName:any;
  mitigationStatus:any;
  residualRiskLikelihoodValue:any;
  residualImpactValue:any;
  residualRiskRatingTotal:any;
  mitigationDate:any;

  constructor(
    private breadCurmbService: BreadcrumbService,
    private grcService:GrcService,
    private workflowService: WorkflowService,
    private router: Router,
    private routerUtilityService: RouterUtilityService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private utils: UtilsService
  ) {
    this.routerParam();
       this.callAllfunctions();
   }

  ngOnInit(): void {
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
  }

  callAllfunctions() {
    this.getRiskRegisterID();
    this.getAllAffectedFunctions();
    this.getAllriskCategory();  
    this.getAllriskTreatment();
    this.getAllstatus();
    this.getAllriskOwner();   
    this.getAllmitigationstatus();
  }
  
  
  updateRisk(form: NgForm) {
    this.hideContent = true;
    const newRiskModel = this.buildCreateRiskeModel(form.value);
  }
  getRiskRegisterID() {
    const url = environment.getRiskId.url;
    const method = environment.getRiskId.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      this.riskId = reponse[0];
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskId = '';
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }

  private buildCreateRiskeModel(riskForm: any) {
    const newRiskModel = Object();
    this.isCreate=true;
    newRiskModel.riskReferenceId = this.riskReferenceIdValue;
    newRiskModel.affectedFunctions = riskForm.affectedFunctions;
    newRiskModel.status = riskForm.status;
    newRiskModel.riskCategory = riskForm.riskCategory;
    newRiskModel.riskMitigationStatus = riskForm.riskMitigationStatus;
    newRiskModel.riskDescription = riskForm.riskDescription;
    newRiskModel.riskTreatment = riskForm.riskTreatment;
    newRiskModel.mitigationstatus = riskForm.mitigationstatus;
    newRiskModel.riskOwner = riskForm.riskOwner;
    newRiskModel.mitigationPlan = riskForm.mitigationPlan;
    newRiskModel.department = riskForm.department;
    newRiskModel.service = riskForm.service;
    // newRiskModel.actualDateOfResolution = riskForm.actualDateOfResolution.singleDate.formatted;
    newRiskModel.dateOfRisk = riskForm.riskDateValue;
    // newRiskModel.plannedResolutionDate = riskForm.plannedResolutionDate.singleDate.formatted;
    newRiskModel.planMitigationDate = riskForm.mitigationDate;
    newRiskModel.riskProbability = riskForm.riskProbability;
    newRiskModel.riskImpact = riskForm.riskImpact;
    newRiskModel.riskValue = riskForm.riskValue;
    newRiskModel.residualRiskLikelihood = riskForm.residualRiskLikelihood;
    newRiskModel.residualImpact = riskForm.residualImpact;
    newRiskModel.residualRiskRating = riskForm.residualRiskRating;
    const url = environment.updateRisk.url;
    const method = environment.updateRisk.method;
    console.log(newRiskModel)
    this.grcService.executeHttpAction(url, method, newRiskModel,).subscribe((event: any) => {
      this.riskLoader = false;
      this.isRiskCreationSuccess = true;
    },
    (error: any) => {
      this.isRiskCreationFailed = true;
      this.riskLoader = false;
    });
  }
  onSelectaffectedFunctions(onSelectaffectedFunctions:any){

  }
  onSelectriskCategory(onSelectriskCategory:any){} 
  onSelectriskTreatment(onSelectriskTreatment:any){
    if(onSelectriskTreatment === 'Mitigation'){
      this.riskTreatmentValue = true;
    } else {
      this.riskTreatmentValue = false;
    }
  }
  onSelectriskOwner(onSelectriskOwner:any){}
  onSelectstatus(onSelectriskOwner:any){}
  onSelectmitigationstatus(onSelectriskOwner:any){}
  onSelectriskMitigationStatus(onSelectriskMitigationStatus:any){} 
  onSelectresidualImpact(onSelectresidualImpact:any){}
  onSelectresidualRiskRating(onSelectresidualRiskRating:any){}
  onSelectionChange1() {
    this.selectedValueChange1.emit(this.selectedValue1);   
  }
  onSelectionChange2() {
    this.selectedValueChange1.emit(this.selectedValue2);   
  }
  onSelectionChange3() {
    this.selectedValueChange1.emit(this.selectedValue3);   
    
  }
  onSelectionChange4() {
    this.selectedValueChange1.emit(this.selectedValue4);   
  }
  onSelectionChange5() {
    this.selectedValueChange1.emit(this.selectedValue5);   
  }
  onSelectionChange6() {
    this.selectedValueChange1.emit(this.selectedValue6);   
  }
  onSelectionChange7() {
    this.selectedValueChange1.emit(this.selectedValue7);   
  }
  onSelectionChange8() {
    this.selectedValueChange1.emit(this.selectedValue8);   
  }

  checkstatus(riskForm:any){
    if(riskForm.dateOfRisk.singleDate.formatted!=undefined){
      return false;
    }else{
      return true;
    }
    
  }
  getAllAffectedFunctions() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskAffectedFunctions.url;
    const method = environment.allRiskAffectedFunctions.method;    
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.affectedFunctions.push(
          {
            id: item.functionName,
            name: item.functionId,
          });
      }
      
    },
      (error: any) => {
        this.affectedFunctions = [];
        this.errorMessage = 'apiResponseError';
      });
  }
  getAllriskCategory() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskCategory.url;
    const method = environment.allRiskCategory.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskCategory.push(
          {
            id: item.category,
            name: item.categoryId,
          });
      }
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskCategory = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }  
    
  getAllriskTreatment() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskTreatment.url;
    const method = environment.allRiskTreatment.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskTreatment.push(
          {
            id: item.riskTreatment,
            name: item.riskTreatmentId,
          });
      }    
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskTreatment = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  getAllstatus() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allstatus.url;
    const method = environment.allstatus.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.status.push(
          {
            id: item.mitigationStatus,
            name: item.mitigationId,
          });
      }    
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskTreatment = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  getAllmitigationstatus() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allmitigationstatus.url;
    const method = environment.allmitigationstatus.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskMitigationStatus.push(
          {
            id: item.mitigationStatus,
            name: item.mitigationId,
          });
      }    
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskTreatment = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  getAllriskOwner() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskOwner.url;
    const method = environment.allRiskOwner.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskOwner.push(
          {
            id: item.ownerName,
            name: item.id,
          });
      }
      // this.riskOwner = reponse[0];
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskOwner = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  navigateBack() {
    this.router.navigate(['compliance/grc/risk-management']);
    // try {
    //   this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
    // } catch (error) {
    //   this.logger.log('error', error);
    // }
  }
  expiryDate: any;
  getDateData(date: any): any {    
    this.expiryDate = date.singleDate.formatted;
    
  }
  getDateData2(date: any): any {    
    this.isRiskValid=true;
    this.expiryDate = date.singleDate.formatted;
    
  }  
  calculateProduct() {
    if (this.riskProbabilityValue !== undefined && this.riskImpactValue !== undefined) {
      this.riskTotal = this.riskProbabilityValue * this.riskImpactValue;
    } else {
      this.riskTotal = 0; // Handle cases where values are not selected
    }
  }
  calculateProduct2() {
    if (this.residualRiskLikelihoodValue !== undefined && this.residualImpactValue !== undefined) {
      this.residualRiskRatingTotal = this.residualRiskLikelihoodValue * this.residualImpactValue;
    } else {
      this.residualRiskRatingTotal = 0; // Handle cases where values are not selected
    }
  }
  closeErrorMessage() {
    if (this.failedTitle === 'Loading Failed') {
      // this.getDomainDetails(this.domainName);
    } else {
      this.hideContent = false;
    }
    this.isRiskCreationFailed = false;
    this.isRiskCreationFailed = false;
  }

  routerParam() {
    try {
      // this.filterText saves the queryparam
      const currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
      if (currentQueryParams) {

        this.FullQueryParams = currentQueryParams;
        this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
        this.riskIdForEdit = this.queryParamsWithoutFilter.riskReferenceId;
        delete this.queryParamsWithoutFilter['filter'];
        console.log("Risk id: ",  this.riskIdForEdit)
        if (this.riskIdForEdit) {
          this.pageTitle = 'Edit Risk';
          this.breadcrumbPresent = 'Edit Risk';
          this.isCreate = false;
          this.getRiskByIdData(this.riskIdForEdit);
        } else {
          this.pageTitle = 'Create Risk';
          this.breadcrumbPresent = 'Create Risk';
          this.isCreate = true;
          this.callAllfunctions();
        }

        /**
         * The below code is added to get URLparameter and queryparameter
         * when the page loads ,only then this function runs and hits the api with the
         * filterText obj processed through processFilterObj function
         */
        this.filterText = this.utils.processFilterObj(
          this.FullQueryParams
        );

        // check for mandatory filters.
        if (this.FullQueryParams.mandatory) {
          this.mandatory = this.FullQueryParams.mandatory;
        }

      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  getRiskByIdData(riskIdForDetails: any) {
    const url = environment.riskById.url;
    const method = environment.riskById.method;
    this.grcService.executeHttpAction(url, method, {}, { riskId: riskIdForDetails }).subscribe((reponse: any) => {
      this.riskDateValue = reponse[0].response[0].dateOfRisk;
      this.affectedValue = reponse[0].response[0].affectedFunctions;
      this.riskReferenceIdValue = reponse[0].response[0].riskReferenceId;
      this.departmentValue = reponse[0].response[0].department;
      this.serviceValue = reponse[0].response[0].service;
      this.categoryValue = reponse[0].response[0].riskCategory;
      this.riskProbabilityValue = reponse[0].response[0].riskProbability;
      this.riskImpactValue = reponse[0].response[0].riskImpact;
      this.riskTotal = reponse[0].response[0].riskValue
      this.riskDescriptionValue = reponse[0].response[0].riskDescription;
      this.riskTreatmentText = reponse[0].response[0].riskTreatment;
      if(this.riskTreatmentText == 'Mitigation'){
        this.riskTreatmentValue = true;
      }
      this.statusValue = reponse[0].response[0].status;
      this.mitigationPlanValue = reponse[0].response[0].mitigationPlan;
      this.mitigationDate = reponse[0].response[0].planMitigationDate;
      this.ownerName = reponse[0].response[0].riskOwner;
      this.mitigationStatus = reponse[0].response[0].riskMitigationStatus;
      this.residualRiskLikelihoodValue = reponse[0].response[0].residualRiskLikelihood;
      this.residualImpactValue = reponse[0].response[0].residualImpact;
      this.residualRiskRatingTotal = reponse[0].response[0].residualRiskRating;

      console.log("details 2: ",  reponse[0].response[0]);
    },
      (error: any) => {
        this.errorMessage = 'apiResponseError';
      });
  }
}
