

import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexGrid,
  ApexYAxis
} from "ng-apexcharts";

import { Subscription } from 'rxjs';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { DomainTypeObservableService } from 'src/app/core/services/domain-type-observable.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { OverallComplianceService } from 'src/app/shared/services/overall-compliance.service';
import { environment } from 'src/environments/environment';
import { AutorefreshService } from '../../services/autorefresh.service';
import { Router } from '@angular/router';
import { TENANT_ID } from 'src/app/shared/constants/tenant';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  yaxis: ApexYAxis
};

@Component({
  selector: 'app-overall-compliance',
  templateUrl: './overall-compliance.component.html',
  styleUrls: ['./overall-compliance.component.css'],
  providers: [
    LoggerService,
    ErrorHandlingService,
    OverallComplianceService,
    AutorefreshService,
  ],
  // encapsulation: ViewEncapsulation.None,
})
export class OverallComplianceComponent implements OnInit {
  @ViewChild('barChart')
  barChart!: ChartComponent;

  private overallComplianceUrl = environment.overallCompliance.url;
  private overallComplianceMethod = environment.overallCompliance.method;
  subscriptionToAssetGroup: Subscription | any;
  subscriptionDomain: Subscription | any;
  subscriptionToOverallCompliance: Subscription | any;
  complianceData: any = [];
  selectedAssetGroup: string = '';
  selectedDomain: any;
  overallPercentage: any;
  errorMessage: any;
  durationParams: any;
  loaded = false;
  autoRefresh = false;
  contValue = false;
  seekdata = false;

  series: any[] = [];
  chartCategories: any[] = [];

  private autorefreshInterval: any;

  public chartOptions: Partial<ChartOptions> | any;

  constructor(
    private overallComplianceService: OverallComplianceService,
    private autorefreshService: AutorefreshService,
    private assetGroupObservableService: AssetGroupObservableService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private domainObservableService: DomainTypeObservableService,
    private router: Router
  ) {
    this.chartOptions = {
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['Security', 'Cost Optimization', 'Governance', 'tagging']
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 2,
      },
      series: [
        {
          data:[0,0,0,0]
        },
      ],

      legend: {
        show: false,
      },

      colors: ['#FC6A59', '#006E74', '#881E87', '#D9434E'],

      chart: {
        type: 'bar',
        height: 150,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          barHeight: '40%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
    };
    this.initializeSubscriptions();
  }
  ngOnInit() {
    /* Variables to be set only first time when component is loaded should go here. */
    try {
      this.durationParams = this.autorefreshService.getDuration();
      this.durationParams = parseInt(this.durationParams, 10);
      this.autoRefresh = this.autorefreshService.autoRefresh;
      const afterLoad = this;
      if (this.autoRefresh !== undefined) {
        if (
          this.autoRefresh === true ||
          this.autoRefresh.toString() === 'true'
        ) {
          this.autorefreshInterval = setInterval(function () {
            afterLoad.getOverallCompliance();
          }, this.durationParams);
        }
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }
  }

  /* subscribe to the asset group and domains mandatory to get data */
  initializeSubscriptions() {
    this.subscriptionToAssetGroup = this.assetGroupObservableService
      .getAssetGroup()
      .subscribe((assetGroupName) => {
        this.selectedAssetGroup = assetGroupName;
      });

    this.subscriptionDomain = this.domainObservableService
      .getDomainType()
      .subscribe((domain) => {
        this.selectedDomain = domain;
        this.updateComponent();
      });
  }

  /* Function to get Data */
  getData() {
    /* All functions to get data should go here */
    this.getOverallCompliance();
  }


  updateComponent() {
    if (this.subscriptionToOverallCompliance) {
      this.subscriptionToOverallCompliance.unsubscribe();
    }
    this.contValue = false;
    this.loaded = false;
    /* All functions variables which are required to be set for component to be reloaded should go here */
    this.getData();
  }

  getOverallCompliance(): void {
    try {
      const tenantId = TENANT_ID.tenantId;
      const queryParams = {
        ag: this.selectedAssetGroup,
        domain: this.selectedDomain,
        tenantId: tenantId
      };
      this.seekdata = false;
      this.subscriptionToOverallCompliance = this.overallComplianceService
        .getOverallCompliance(
          queryParams,
          this.overallComplianceUrl,
          this.overallComplianceMethod
        )
        .subscribe(
          (response) => {
            try {
              this.contValue = true;
              this.loaded = true;
              this.complianceData = response[0].data;

              if (this.complianceData.length === 0) {
                this.seekdata = true;
                this.errorMessage = 'noDataAvailable';
              } else {
                this.seekdata = false;
                this.overallPercentage = response[0].percent;
              }

              this.series = [];
              this.chartCategories = [];

              const dataObj: any = {
                'Security': 0,
                'Cost Optimization': 0,
                'Governance': 0,
                'tagging': 0,
              };

              this.complianceData.forEach((element: any) => {
                element.forEach((el: any) => {
                  if (
                    el.title === 'Security' ||
                    el.title === 'Cost Optimization' ||
                    el.title === 'Governance' ||
                    el.title === 'tagging'
                  ) {
                    dataObj[el.title] = el.val
                    this.chartCategories.push(el.title);
                    this.series.push(el.val);
                  }
                });
              });

              // update chart data

              //TODO: Need to confirmation on what to do when we dont have data to show on garph
              // if (!this.series.length) {
              //   this.series = [0,0,0,0]
              // }

              // if (!this.chartCategories.length) {
              //   this.chartCategories = ['Security', 'Cost Optimization', 'Governance', 'Tagging']
              // }

              this.series = Object.values(dataObj);
              this.chartCategories = Object.keys(dataObj);

              this.chartOptions.series = [
                {
                  data: this.series,
                },
              ];

              this.chartOptions.xaxis = {
                categories: this.chartCategories,
              };
            } catch (e) {
              this.seekdata = true;
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {
            this.contValue = true;
            this.seekdata = true;
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }

    // this.series = [];
    // this.chartCategories =[];
  }

  ngOnDestroy() {
    try {
      this.subscriptionToAssetGroup.unsubscribe();
      this.subscriptionDomain.unsubscribe();
      this.subscriptionToOverallCompliance.unsubscribe();
      clearInterval(this.autorefreshInterval);
    } catch (error) {
      this.logger.log('info', '--- Error while unsubscribing ---');
    }
  }
}
