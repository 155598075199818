import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { FilterManagementService } from '../../services/filter-management.service';
import { LoggerService } from '../../services/logger.service';
import { RefactorFieldsService } from '../../services/refactor-fields.service';
import { RouterUtilityService } from '../../services/router-utility.service';
import { UtilsService } from '../../services/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-grc-risk-table',
  templateUrl: './grc-risk-table.component.html',
  styleUrls: ['./grc-risk-table.component.css']
})
export class GrcRiskTableComponent implements OnInit {
  @ViewChild('TABLE') datatable: ElementRef | any;
    dtOptions: DataTables.Settings = {};
    @Input() dataHead :any ;
    @Input() searchableHeader :boolean= false;
    @Input() heightValue :any;
    @Input() rowHeaders: any;
    @Input() showingArr: any = [];
    @Input() allColumns: any = [];
    @Input() totalRows: any;
    @Input() firstPaginator: number =0;
    @Input() lastPaginator: number=0;
    @Input() currentPointer: number=0;
    @Input() headerColName = '';
    @Input() direction = 0;
    @Input() paginatorAbsent = false;
    @Input() tabFilterProperty :any;
    @Input() cbModel :any;
    @Input() checkBox :any;
    @Input() columnWhiteList :any;
    @Input() buttonsArr :any;
    @Input() searchTextValues = '';
    @Input() popRows: any;
    @Input() parentName: any;
    @Input() buttonColumn :any;
    @Input() errorValue: any;
    @Input() showGenericMessage: any;
    @Input() tableIdAppend: any;
    @Input() searchPassed = '';
    @Input() checkedList :any= [];
    @Output() selectedRow = new EventEmitter<any>();
    @Output() menuClick = new EventEmitter<any>();
    @Output() searchRowTxt = new EventEmitter<any>();
    @Output() noScrollDetected = new EventEmitter<any>();
    @Output() searchTriggerred = new EventEmitter<any>();
    @Output() previousPageCalled = new EventEmitter<any>();
    @Output() tabSelected = new EventEmitter<any>();
    @Output() nextPageCalled = new EventEmitter<any>();
    @Output() cbClicked = new EventEmitter<any>();
    @Output() rowClickText = new EventEmitter<string>();
    @Output() emitPaginator =new EventEmitter<any>();
  
    @Input() regulatoryData:any ;
    @Input() id:any;
    @Input() notificationHeader:boolean = true;
    showRegulatoryCards: boolean = true;
    regulatoryCompliance: any;
  
    errorMessage = 'apiResponseError';
    indexSelected: number =0;
    sortArr: any = [];
    rowDetails: any = [];
    rowIndex = -1;
    noMinHeight = false;
    rowObj: any = {};
    searchVal = '';
    loaded = false;
    sortSelectedColomn = '';
  
    seekdata = false;
    showError = false;
    scrollEnabled = false;
     rowAccessProperty = 'text';
    private allTableData :any;
     tabsData :any;
    public filteredColumns :any = [];
    public animationState :any;
    public previousTableData:any = [];
    public currentTableData:any = [];
    currentRowData: any;
    appliedFilters: any = {
      queryParamsWithoutFilter: {} /* Stores the query parameter ibject without filter */,
      pageLevelAppliedFilters: {} /* Stores the query parameter ibject without filter */
    }
    filterArray: any = [];
    isFilterRquiredOnPage = true;
    rowCol: any = [];
    tableNames:any = [];
    riskImpacts:any = [];
    impactTotal: any = [];
    severityTotal:any = [];
  
    constructor(
      private refactorFieldsService: RefactorFieldsService,
      private logger: LoggerService, private router: Router,
      private utils: UtilsService,private activatedRoute: ActivatedRoute,
      private filterManagementService: FilterManagementService,
      private errorHandling: ErrorHandlingService,
      private routerUtilityService: RouterUtilityService) {
  }
  
    ngOnInit() {
        this.dtOptions = {
          scrollCollapse: true,
          paging:false,
          scrollX:true,
          searching: false,
          ordering: true,
          scrollY: '145px',
          };
        this.noMinHeight = false;
        this.seekdata = false;
        if ((this.rowHeaders !== undefined) && (this.rowHeaders.length !== 0)) {
            this.currentTableData = this.rowHeaders;
            this.allTableData = this.rowHeaders.riskImpactRating;
            this.rowCol = Object.keys(this.rowHeaders.riskImpactRating);
            this.impactTotal = this.rowHeaders.countByImpact;
            this.severityTotal = this.rowHeaders.countBySeverity;
            if (this.tabFilterProperty) {
                this.tabsData = _(this.allTableData)
                    .map((row: any) => {
                        return row[this.tabFilterProperty];
                    })
                    .filter((row) => {
                        return !!row[this.rowAccessProperty];
                    })
                    .uniqBy((row) => {
                        return row[this.rowAccessProperty];
                    })
                    .value();
  
                for (let index = 0; index < this.tabsData.length; index++ ) {
                    this.tabsData[index]['displayName'] = this.refactorFieldsService.getDisplayNameForAKey(this.tabsData[index]['text'].toLowerCase()) || this.tabsData[index]['text'];
                }
            }
            this.restrictShownColumns(this.columnWhiteList);
            for (let column_index = 0; column_index < this.allColumns.length; column_index++) {
                this.sortArr[column_index] = {
                    'showUp': undefined,
                    'direction': 0
                };
            }
            this.loaded = true;
        } else {
            this.seekdata = true;
            this.showError = true;

            if (this.showGenericMessage === true) {
                this.errorMessage = 'apiResponseError';
            } else {
  
                if (this.searchTextValues === '') {
                    this.errorMessage = this.parentName;
                } else {
                    this.errorMessage = 'dataTableMessage';
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const graphDataChange = changes['allColumns'];
        const dataChange = changes['rowHeaders'];
        const errorChange = changes['showGenericMessage'];
        const errorMsg = changes['parentName'];
        const errorValueChange = changes['errorValue'];
        const searchTextChnage = changes['searchTextValues'];
        if (dataChange) {
            const cur = JSON.stringify(dataChange.currentValue);
            const prev = JSON.stringify(dataChange.previousValue);
            if ((cur !== prev) && (this.allColumns)) {
                this.ngOnInit();
            }
        }
        if (graphDataChange) {
            const cur = JSON.stringify(graphDataChange.currentValue);
            const prev = JSON.stringify(graphDataChange.previousValue);
            if ((cur !== prev) && (this.allColumns)) {
                this.ngOnInit();
            }
        }
  
        if (errorChange) {
            const cur = JSON.stringify(errorChange.currentValue);
            const prev = JSON.stringify(errorChange.previousValue);
            if ((cur !== prev)) {
                this.ngOnInit();
            }
        }
  
        if (searchTextChnage) {
            const cur = JSON.stringify(searchTextChnage.currentValue);
            const prev = JSON.stringify(searchTextChnage.previousValue);
            if ((cur !== prev)) {
                this.ngOnInit();
            }
        }
  
        if (errorMsg) {
            const cur:any = errorMsg.currentValue;
            this.errorMessage = cur;
        }
  
        if (errorValueChange) {
            if (errorValueChange && (errorValueChange.currentValue === 0 || this.errorValue.currentValue === -1)) {
                this.currentTableData = [];
            }
        }
    }
  
    init() {
      /* Initialize */
      this.routerParam();
    }
  
    headerClicked(index :any, header :any) {
      this.rowIndex = -1;
        for (let i = 0; i < this.sortArr.length; i++) {
            if (i !== index) {
                this.sortArr[i].showUp = undefined;
                this.sortArr[i].direction = 0;
            } else {
                if (this.sortArr[i].direction === 0) {
                    this.sortArr[i].direction = 1;
                } else {
                    this.sortArr[i].direction = this.sortArr[i].direction * -1;
                }
                this.direction = this.sortArr[i].direction;
            }
        }
        this.indexSelected = index;
        this.headerColName = header;
  
        // for sort selected cloumn 
        this.sortSelectedColomn = header;
    }
  
  
    tableRowClicked(rowDetails :any, index :any) {
        this.rowDetails = [];
        this.rowObj = {};
        this.rowObj = rowDetails;
        this.rowDetails = Object.keys(this.rowObj);
        this.rowIndex = index;
    }
  
    goToDetails(thisRow :any, thisCol :any) {
      if(thisCol === 'Policy Name') {
          this.router.navigateByUrl('compliance/compliance/compliance-dashboard?ag=aws&domain=Infra%20%26%20Platforms');
          return;
      }
        const details = {
            row: '',
            col: ''
        };
        details.row = thisRow;
        details.col = !!thisCol.value ? thisCol.value : thisCol;
        this.selectedRow.emit(details);
    }
  
    searchCalled(searchQuery :any) {
        this.searchRowTxt.emit(searchQuery);
        this.searchVal = searchQuery;
    }
  
    callCheckbox(i :any, row :any) {
        const data:any = {};
        data['index'] = i;
        data['data'] = row;
        this.cbClicked.emit(data);
    }
  
    triggerSearch() {
        this.noMinHeight = false;
        this.searchTriggerred.emit();
    }
  
    restrictShownColumns(columnNames :any) {
        if (columnNames) {
            const list = _.filter(columnNames, (whiteListedColumn) => {
                return _.find(this.allColumns, (column) => {
                    return whiteListedColumn.toLowerCase() === column.toLowerCase();
                });
            });
            this.filteredColumns = list;
        } else {
            this.filteredColumns =  this.tableNames;
        }
    }
  
    prevPage() {
      if (this.currentPointer > 0) {
          this.previousPageCalled.emit();
      }
  }
  
  nextPage() {
      if (this.lastPaginator < this.totalRows) {
          this.nextPageCalled.emit();
      }
  }
  
  
    emitRowText(rowClickText :any) {
        this.rowClickText.emit(rowClickText);
    }
  
    updateUrlWithNewFilters(filterArr: any[]) {
      this.appliedFilters.pageLevelAppliedFilters = this.utils.arrayToObject(
        this.filterArray,
        'filterkey',
        'value'
      ); // <-- TO update the queryparam which is passed in the filter of the api
      this.appliedFilters.pageLevelAppliedFilters = this.utils.makeFilterObj(
        this.appliedFilters.pageLevelAppliedFilters
      );
  
      /**
       * To change the url
       * with the deleted filter value along with the other existing paramter(ex-->tv:true)
       */
  
      const updatedFilters = Object.assign(
        this.appliedFilters.pageLevelAppliedFilters,
        this.appliedFilters.queryParamsWithoutFilter
      );
  
      /*
       Update url with new filters
       */
  
      this.router
        .navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: updatedFilters
        })
        .then(success => {
          this.routerParam();
        });
    }
  
    routerParam() {
      try {
        const currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(
          this.router.routerState.snapshot.root
        );
  
        if (currentQueryParams) {
          this.appliedFilters.queryParamsWithoutFilter = JSON.parse(
            JSON.stringify(currentQueryParams)
          );
          delete this.appliedFilters.queryParamsWithoutFilter['filter'];
  
          this.appliedFilters.pageLevelAppliedFilters = this.utils.processFilterObj(
            currentQueryParams
          );
  
          this.filterArray = this.filterManagementService.getFilterArray(
            this.appliedFilters.pageLevelAppliedFilters
          );
        }
      } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.logger.log('error', error);
      }
    }
    exportAsExcel() {
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.datatable.nativeElement);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
      /* save to file */
      XLSX.writeFile(wb, 'SheetJS.xlsx');
    }

    getTotalCount(): number {
        return this.impactTotal.reduce((acc:any, value:any) => acc + value, 0);
      }
}
