
import {Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { ComplianceOverviewService } from '../../services/compliance-overview.service';
import { Subscription } from 'rxjs/Subscription';
import { AssetGroupObservableService } from '../../../core/services/asset-group-observable.service';
import { SelectComplianceDropdown } from '../../services/select-compliance-dropdown.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import {AutorefreshService} from '../../services/autorefresh.service';
import {environment} from "../../../../environments/environment";
import { TENANT_ID } from 'src/app/shared/constants/tenant';

@Component({
  selector: 'app-tagging-compliance-trend',
  templateUrl: './tagging-compliance-trend.component.html',
  styleUrls: ['./tagging-compliance-trend.component.css'],
  providers: [ ComplianceOverviewService, AutorefreshService ],
})

export class TaggingComplianceTrendComponent implements OnInit, OnDestroy,AfterViewInit {

  @ViewChild('taggingComplianceOverviewContainer') widgetContainer: ElementRef |any;

  private assetGroupSubscription: Subscription;
  private complianceDropdownSubscription: Subscription;
  private issuesSubscription: Subscription |any;
  private selectedAssetGroup: any = 'rebellion';

   graphWidth: any;
   graphData: any;
   dataLoaded: any = false;
   error: any = false;
  private loading: any = false;
   errorMessage: any = 'apiResponseError';
  private distributedFiltersObject: any = {};

  // Graph customization variables
   yAxisLabel = 'Compliance %';
   showGraphLegend = true;
   showArea = false;
   singlePercentLine = true;

  private autorefreshInterval:any;

  durationParams: any;
    autoRefresh: boolean =false;

  constructor(private complianceOverviewService: ComplianceOverviewService,
              private assetGroupObservableService: AssetGroupObservableService,
              private selectComplianceDropdown: SelectComplianceDropdown,
              private autorefreshService: AutorefreshService,
              private logger: LoggerService, private errorHandling: ErrorHandlingService) {

        // Get latest asset group selected and re-plot the graph
        this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(
            assetGroupName => {
                this.selectedAssetGroup = assetGroupName;
                this.init();
            });

        // Get latest targetType/Application/Environment
        this.complianceDropdownSubscription = this.selectComplianceDropdown.getCompliance().subscribe(
            distributedFiltersObject => {
                this.distributedFiltersObject = distributedFiltersObject;
            });
  }


  onResize() {
  }

  getOverview() {
      try {

        if (this.issuesSubscription) {
          this.issuesSubscription.unsubscribe();
        }

          const complianceOverviewUrl = environment.taggingComplianceTrend.url;
          const method = environment.taggingComplianceTrend.method;

        const prevDate = new Date();
        prevDate.setMonth(prevDate.getMonth() - 1);
        let fromDay;
        fromDay = prevDate.toISOString().split('T')[0];

        const queryParameters = {
            'ag': this.selectedAssetGroup,
            'from': fromDay,
            'filters': {}
        };
        const tenantId = TENANT_ID;
          this.issuesSubscription = this.complianceOverviewService.getWeeklyData(complianceOverviewUrl, method, queryParameters, tenantId).subscribe(
              response => {
                  try {

                    if (response.length) {
                      response.forEach((type:any) => {
                        const key = type.key.toLowerCase();
                        if (key === 'compliance_percent') {
                            this.graphData = [type];
                        }
                      });

                      this.setDataLoaded();
                    } else {
                      this.setError('noDataAvailable');
                    }

                  } catch (error) {
                      this.setError('jsError');
                  }
              },
              error => {
                  this.setError('apiResponseError');
              }
          );
      } catch (error) {
          this.setError('jsError');
      }
  }


  getData() {
      this.getOverview();
  }

  init() {
      this.setDataLoading();
      this.getData();
  }

  setDataLoaded() {
      this.dataLoaded = true;
      this.error = false;
      this.loading = false;
  }

  setDataLoading() {
      this.dataLoaded = false;
      this.error = false;
      this.loading = true;
  }

  setError(message?: any) {
      this.dataLoaded = false;
      this.error = true;
      this.loading = false;
      if (message) {
          this.errorMessage = message;
      }
  }

  ngOnInit() {

      this.durationParams = this.autorefreshService.getDuration();
      this.durationParams = parseInt(this.durationParams, 10);
      this.autoRefresh = this.autorefreshService.autoRefresh;

      const afterLoad = this;
        if (this.autoRefresh !== undefined) {
          if ((this.autoRefresh === true ) || (this.autoRefresh.toString() === 'true')) {

            this.autorefreshInterval = setInterval(function() {
              afterLoad.getData();
            }, this.durationParams);
          }
        }
  }

  ngOnDestroy() {
      try {
          this.issuesSubscription.unsubscribe();
          this.assetGroupSubscription.unsubscribe();
          this.complianceDropdownSubscription.unsubscribe();
          clearInterval(this.autorefreshInterval);
      } catch (error) {
          this.logger.log('error', '--- Error while unsubscribing ---');
      }
  }

  ngAfterViewInit(): void {

  }

}

