import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { environment } from './../../../environments/environment';
import { HttpService } from '../../shared/services/http-response.service';
import { LoggerService } from '../../shared/services/logger.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import { TENANT_ID } from 'src/app/shared/constants/tenant';

@Injectable()
export class PolicyTrendService {
  constructor(
    @Inject(HttpService) private httpService: HttpService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService
  ) {}

  getData(_severeties: never[], payload: any): Observable<any> {
    const historyUrl = environment.policyTrend.url;
    const method = environment.policyTrend.method;
    const queryParams = TENANT_ID;
    return this.httpService
      .getHttpResponse(historyUrl, method, payload, queryParams)
      .map((response:any) => this.massageResponse(response['data'].response));
  }

  massageResponse(data: {
    hasOwnProperty: (arg0: string) => any;
    compliance_trend: any;
  }) {
    const finalData: {}[] = [];
    if (data.hasOwnProperty('compliance_trend')) {
      const data_trend = data.compliance_trend;
      if (data_trend.length) {
        const types = Object.keys(data_trend[0]['compliance_info'][0]);
        types.splice(types.indexOf('date'), 1);
        types.forEach((type) => {
          let formattedObject = {};
          const values: { date: Date; value: any; 'zero-value': boolean }[] =
            [];
          data_trend.forEach((weeklyData: { [x: string]: any }) => {
            const apiResponse = weeklyData['compliance_info'];
            apiResponse.forEach((details: { [x: string]: number }) => {
              const obj = {
                date: new Date(details['date']),
                value: details[type],
                'zero-value': details[type] === 0 ? true : false,
              };
              values.push(obj);
            });
          });
          formattedObject = {
            key: type,
            values: values,
          };
          if (
            type.toLowerCase() !== 'overall' &&
            type.toLowerCase() !== 'total'
          ) {
            finalData.unshift(formattedObject);
          } else {
            finalData.push(formattedObject);
          }
        });
      }
    }
    return finalData;
  }
}
