import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { AutorefreshService } from '../../services/autorefresh.service';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { VulnerabilityAcrossApplicationService } from 'src/app/shared/services/vulnerability-across-application.service';

@Component({
  selector: 'app-vulnerability-across-application',
  templateUrl: './vulnerability-across-application.component.html',
  styleUrls: ['./vulnerability-across-application.component.css'],
  // providers: [VulnerabilityAcrossApplicationComponent, AutorefreshService]

})
export class VulnerabilityAcrossApplicationComponent implements OnInit {

  selectedAssetGroup!: string;
  public apiData: any;
  public applicationValue: any;
  public errorMessage: any;
  public dataComing = true;
  public showLoader = true;
  datacoming:any;
  public tableHeaderData: any;
  private subscriptionToAssetGroup: Subscription;
  private dataSubscription: Subscription |any;
  public placeHolderText: any;
  public returnedSearch = '';
  public seekdata = false;
  public sampleData:any;
  durationParams: any;
  autoRefresh!: boolean;
  public ruleID: any = '';
  routeTo = 'vulnerabilities';
  public applicationName: any = '';
  apiAppData: any = {};
  apiEnvData: any = {};
  showEnv = true;
  showAsset = false;
  private autorefreshInterval:any;
  private urlToRedirect:any;



  constructor(

    private vulnerabilityAcrossApplicationService: VulnerabilityAcrossApplicationService,
    private assetGroupObservableService: AssetGroupObservableService,
    private autorefreshService: AutorefreshService,
    private logger: LoggerService, private errorHandling: ErrorHandlingService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private utils: UtilsService, private workflowService: WorkflowService

  ) {

    this.subscriptionToAssetGroup = this.assetGroupObservableService.getAssetGroup().subscribe(
      assetGroupName => {
          this.selectedAssetGroup = assetGroupName;
          this.updateComponent();
    });

  }


  ngOnInit() {
    this.urlToRedirect = this.router.routerState.snapshot.url;
    this.durationParams = this.autorefreshService.getDuration();
    this.durationParams = parseInt(this.durationParams, 10);
    this.autoRefresh = this.autorefreshService.autoRefresh;

    const afterLoad = this;
        if (this.autoRefresh !== undefined) {
          if ((this.autoRefresh === true ) || (this.autoRefresh.toString() === 'true')) {

            this.autorefreshInterval = setInterval(function() {
              afterLoad.getData();
            }, this.durationParams);
          }
        }

    this.placeHolderText = 'Search by App';
  }
  getSearch(search:any) {
    this.returnedSearch = search;
  }
   /* Function to repaint component */
   updateComponent() {

      /* All functions variables which are required to be set for component to be reloaded should go here */

      this.showLoader = true;
      this.dataComing = false;
      this.seekdata = false;
      this.showEnv = false;
      this.showAsset = true;
      this.returnedSearch = '';
      this.getData();
  }

  /* Function to get Data */
  getData() {
    

    if (this.dataSubscription) { this.dataSubscription.unsubscribe(); }

      /* All functions to get data should go here */
       this.getVulnerabilityAcrossApplication();
  }
  getVulnerabilityAcrossApplication() {

    const queryParams = {
        'ag': this.selectedAssetGroup
    };

    const vulnerabilityAcrossApplicationUrl = environment.vulnerabilityAcrossApplication.url;
    const vulnerabilityAcrossApplicationMethod = environment.vulnerabilityAcrossApplication.method;


    this.dataSubscription = this.vulnerabilityAcrossApplicationService.getData(queryParams, vulnerabilityAcrossApplicationUrl, vulnerabilityAcrossApplicationMethod).subscribe(
      (response:any) => {
        try {

          if (response.length === 0) {
            this.getErrorValues();
            this.errorMessage = 'vulnerabilityMessage';
          } else {

            this.processAppData(response);

             if (response.length === 1) {
              /**
               * call the funtion which hits the 2nd api
               */
              this.applicationName = response[0].AppName;
              this.getPolicyAcrossEnv();
             } else {
                this.showAsset = true;
                this.showEnv = false;
                this.showLoader = false;
                this.seekdata = false;
                this.dataComing = true;
             }
          }

        }catch (e) {
            this.errorMessage = this.errorHandling.handleJavascriptError(e);
            this.getErrorValues();
        }
    },
    (error:any) => {
      this.errorMessage = error;
      this.getErrorValues();
    });
  }


   /**
   * this function gets environment  details for  a particular application
   * this funtion is call if the api response = 1 from getPolicyAcrossApplication function
   */


  getPolicyAcrossEnv() {

    const queryParams = {
        'ag': this.selectedAssetGroup,
        'application' : this.applicationName
    };

    const vulnerabilityAcrossEnvUrl = environment.vulnerabilityAcrossEnv.url;
    const vulnerabilityAcrossEnvMethod = environment.vulnerabilityAcrossEnv.method;


    this.dataSubscription = this.vulnerabilityAcrossApplicationService.getpolicyEnv(queryParams, vulnerabilityAcrossEnvUrl, vulnerabilityAcrossEnvMethod).subscribe(
      (response:any) => {
        try {

          this.showLoader = false;
          this.seekdata = false;
          this.dataComing = true;

          if (response.length === 0) {

            this.showEnv = false;
            this.showAsset = true;
          } else {

            this.showEnv = true;
            this.showAsset = false;
            this.processEnvData(response);
          }

        } catch (e) {
            this.errorMessage = this.errorHandling.handleJavascriptError(e);
            this.getErrorValues();
        }
    },
    (error:any) => {
      this.errorMessage = error;
      this.getErrorValues();
    });
  }

  /**
   * This function  is called to set the error blocks
   */
  getErrorValues(message?: any): void {
    this.showEnv = false;
    this.showAsset = true;
    this.showLoader = false;
    this.dataComing = false;
    this.seekdata = true;
    if (message) {
      this.errorMessage = message;
    }
  }


  processAppData(data:any) {
    this.tableHeaderData = ['', 'S3', 'S4', 'S5'];
    this.apiAppData = data;
  }
  processEnvData(data:any) {

    this.showEnv = true;
    this.tableHeaderData = ['', 'S3', 'S4', 'S5'];
    this.apiEnvData = data;
  }

  /**
   * This function navigates the page mentioned in the routeTo variable with a querypareams
   */

  navigatePage (event:any) {
    this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
    try {
      const localObjKeys = Object.keys(event);
      const currentApp = event[localObjKeys[0]];
      let currentSeverity = event[localObjKeys[1]];
      currentSeverity = currentSeverity.charAt(1);
      const eachParams = { 'tags.Application.keyword': currentApp, 'severitylevel' : currentSeverity };
      const newParams = this.utils.makeFilterObj(eachParams);


      if ((currentSeverity !== undefined) && (currentApp !== undefined) && (this.routeTo !== undefined) ) {
        console.log(this.routeTo);
        this.router.navigate(['../vulnerabilities-compliance', this.routeTo], { relativeTo: this.activatedRoute, queryParams: newParams, queryParamsHandling: 'merge'});
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
  navigatePageEnv (event:any) {
    this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
    try {
      const localObjKeys = Object.keys(event);
      const currentApp = event[localObjKeys[0]];
      let currentSeverity = event[localObjKeys[1]];
      currentSeverity = currentSeverity.charAt(1);
      const eachParams = { 'tags.Environment.keyword': currentApp, 'severitylevel' : currentSeverity };
      const newParams = this.utils.makeFilterObj(eachParams);


      if ((currentSeverity !== undefined) && (currentApp !== undefined) && (this.routeTo !== undefined)) {
        this.router.navigate(['compliance', {outlets: {details: [this.routeTo]}}], { queryParams: newParams, queryParamsHandling: 'merge'});
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
  /* navigatePage function ends here */


  ngOnDestroy() {
    try {
      this.subscriptionToAssetGroup.unsubscribe();
      this.dataSubscription.unsubscribe();
      clearInterval(this.autorefreshInterval);
    } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.getErrorValues();
    }
  }
}
