<!-- Compliance Tagging  Start -->
<section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12">
                <div class="card tab-box">

                            <!-- Loader -->
            <div *ngIf="!filteredResources.length" class="loader-container">
                <div class="loader">
                    <span></span>
                </div>
             </div>
                    <!--Table Container start here -->
                    <!-- Tab Content Start -->
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li (click)="filterByCategory(category)" *ngFor="let category of categories; let i = index"
                            class="nav-item" role="presentation">
                            <button class="nav-link" data-bs-toggle="pill" data-bs-target="#pills-1" type="button"
                                role="tab" aria-controls="pills-home" aria-selected="true">{{category.name}}</button>
                        </li>
                    </ul>


                    <div [ngClass]="{'cards-container': !isViewMore}" class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade active show" id="pills-1" role="tabpanel">

                            <div class="row">
                                <div *ngFor="let resource of filteredResources; let i = index" class="asset collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false"
                                    aria-controls="flush-collapseOne">
                                    <div class="col-md-2">
                                        <div class="asset-box">
                                            <div class="row" (click)="triggerGraph(resource.type)" >
                                                <!-- <div class="col-md-12 pt-2"><img src="../../../../assets/images/asset-icon.png"></div> -->
                                                <div class="col-md-12 pt-2"><img src='../../../../assets/aws/{{ resource.type + ".svg" }}'></div>

                                                <div class="col-6 card-truncate"> {{resource.type}} </div>
                                                <div class="col-6 border-start">{{resource.count}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="no-content" *ngIf="filteredResources.length == 0">No Items Available In This Category</div> -->

                            </div>

                        </div>


                    </div>
                    <div class="view-more-conatiner" *ngIf="filteredResources.length">
                        <!-- <div *ngIf='!isViewMore' (click)="viewmore('viewmore')">View more</div>
                        <div *ngIf='isViewMore' (click)="viewmore('viewless')">View less</div> -->
                        <button *ngIf='!isViewMore' class="btn btn-outline-secondary btn-sm" type="button" (click)="viewmore('viewmore')">
                            View More
                        </button>
                        <button *ngIf='isViewMore' class="btn btn-outline-secondary btn-sm" type="button" (click)="viewmore('viewless')">
                            View Less
                        </button>
                           <!-- <div *ngIf='!isViewMore' (click)="viewmore('viewmore')"><img class="img-plus" src="assets/images/plus.svg" alt="" /></div> 
                            <div *ngIf='isViewMore' (click)="viewmore('viewless')"><img class="img-plus" src="assets/images/minus.svg" alt="" /></div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>