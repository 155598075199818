<div *ngIf="!showLoader">
<section>
  <div class="container-fluid py-1">   
    <div class="row">
      <div class="col-md-3" *ngFor="let data of issueTopblocks">
        <div class="box-green box-green-vh text-center p-3">         
           <div class="col">
             <h4>{{data.header}}</h4>
             <img src={{data.img}} width="36">
             <h6>{{data.footer}}</h6>
           </div>
        </div>
      </div>
           

      <!-- <div class="col-md-2-5">

        <a href="#" data-bs-toggle="modal" data-bs-target="#modalForm1">  
        <div class="box-green box-green-vh text-center p-3">         
           <div class="col">
             <h4>Additional Actions</h4>
             <img src="/assets/icons/email-out.svg" width="32">
             <h6>Email an issue</h6>
           </div>
        </div>
        </a> 
      </div> -->

       </div>
      </div>

   </section>

   <app-policy-violation-desc [autofixData]='autofixData' [pageLevel]="pageLevel" [violationData]="issueBlocks"></app-policy-violation-desc>

   <div  *ngIf="tagsData">
    <app-filter-info [avoidTags]='true' [tags]="tagsData" [header]="'Target Entity Mandatory Tags'"></app-filter-info>
</div>

<div *ngIf="issueAudit && issueBlocks" class="allpolicy hidesearch">
    <app-policy-violation-table [tableIdAppend]="'8'" [errorValue]='errorValue' [allColumns]='allColumns' [outerArr]='outerArr' [heightValue]="'27.4em'" [totalRows]='totalRows' [firstPaginator]='firstPaginator' [lastPaginator]='lastPaginator' [currentPointer]='currentPointer' (previousPageCalled)='prevPg()' (nextPageCalled)='nextPg()' [searchableHeader]='true' (searchRowTxt)='searchCalled($event)' (searchTriggerred)='callNewSearch()' [dataHead]="'Issue Audit Log'" [searchableHeader] ='false'></app-policy-violation-table>
</div>

</div>

<div *ngIf="showLoader || seekdata == true" class="issue-detail-loader-conatiner"> 
<div *ngIf="showLoader" class="loader-container">
  <div class="loader">
    <span></span>
  </div>
</div>

<div *ngIf='seekdata == true' class="error_handling">
  <app-error-message [selectedValue]="errorMessage"></app-error-message>
</div>
</div>


 <!-- Popup -->
 <section>
  <div class="modal fade " id="modalForm1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog sendmail">
      <div class="modal-content ">
        <div class="modal-header bg-dark text-white">
          <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>

          <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close"><i
              class="bi bi-x-lg text-white"></i></button>

        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3 pt-5">


              <form>
                <div class="mb-3 pt-0">
                  <label class="form-label">From</label>
                  <input type="text" value="violations.support@ustmcm.org" class="form-control" id="username"
                    name="username">
                </div>
                <div class="mb-3">
                  <label class="form-label">To</label>
                  <input type="text" class="form-control" id="password-field" name="toemail"
                    placeholder="Enter Email">

                </div>

                <div class="modal-footer border-0 px-0">

                  <button type="submit" class="btn signin-button w-100 " (click)='onSubmitemail()'>Submit</button>
                </div>
              </form>



              <!-- <table width="100%">
                  <tr>
                    <td  width="25%">From:</td>
                    <td width="75%" class="text-green">violations.support@ustmcm.org</td>
                  </tr>

                  <tr>
                    <td>To:</td>
                    <td class="text-green"><input></td>
                  </tr>

                  <tr>

                    <td></td>

                    <td>

                  <button type="button" class="btn btn-sm box-green mail-btn text-center w-50">Send</button>
                </td>


                 </table> -->



            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</section>