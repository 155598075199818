<!-- ======= Header ======= -->
<header class="header fixed-top d-flex align-items-center">

  <div class="d-flex align-items-center justify-content-between">
    <a class="logo d-flex align-items-center">
      <img width="120" src="/assets/images/pace.svg" alt="">
    </a>
  </div>

  <nav class="header-nav ms-auto">
    <ul class="d-flex align-items-center">
      <app-user-info [firstName]='firstName'></app-user-info>
    </ul>
  </nav>
</header>