<section>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div
          *ngIf="targetTypeLoader"
          class="card dataTable-section-height">
          <div
            *ngIf="targetTypeLoader"
            class="loader-container">
            <div class="loader">
              <span></span>
            </div>
          </div>

          <div
            *ngIf="targetTypeLoader"
            class="d-flex align-items-center">
            <div class="w-100 text-center">
              <div>
                <div>
                  <app-error-message [selectedValue]="errorMessage"></app-error-message>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card tab-box"
          *ngIf="!targetTypeLoader">
          <div class="container-fluid mt-2 p-1">
            <div class="row">
              <div class="col-md-3 mb-4">
                <h6 class="card-title">Target Type Details</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 mx-auto ps-5">
                <div
                  class="row"
                  [hidden]="hideContent">
                  <div class="col-md-6 mb-4">
                    <div class="heading-gray">Select Domain *</div>
                    <ngx-select
                      [allowClear]="true"
                      name="exceptionDetailsForm.assetGroup"
                      [items]="allDomainDetails"
                      [(ngModel)]="targetTypes.domain"
                      placeholder="Select Domain">
                    </ngx-select>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="heading-gray">Select Category *</div>
                    <ngx-select
                      [allowClear]="true"
                      name="exceptionDetailsForm.assetGroup"
                      [items]="allCategoryDetails"
                      [(ngModel)]="targetTypes.category"
                      placeholder="Select Category">
                    </ngx-select>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="heading-gray">Config *</div>
                    <textarea
                      name="description"
                      class="input-field"
                      [(ngModel)]="targetTypes.config"
                      placeholder="Enter Target Type Config"></textarea>
                  </div>
                  <div class="col-md-6 mb-4 mt-0">
                    <div class="heading-gray">Description *</div>
                    <textarea
                      name="description"
                      class="input-field"
                      [(ngModel)]="targetTypes.desc"
                      placeholder="Enter Target Type Description"></textarea>
                  </div>
                  <div
                    class="col-md-6 mb-4"
                    *ngIf="isCreate">
                    <div class="heading-gray">Target Type Name *</div>
                    <input type="text" name="type" class="input-field ng-pristine ng-valid ng-touched" [(ngModel)]="targetTypes.name" placeholder="Enter Target Type Name" autocomplete="off">
                    <!-- <ngx-select
                      [allowClear]="true"
                      name="exceptionDetailsForm.assetGroup"
                      [items]="allCategoryDetails"
                      [(ngModel)]="targetTypes.name"
                      placeholder="Select Target Type">
                    </ngx-select> -->
                  </div>
                  <div
                    class="col-md-6 mb-4"
                    *ngIf="!isCreate">
                    <div class="heading-gray">Target Type Name *</div>
                    <input type="text" name="type" class="input-field ng-pristine ng-valid ng-touched" [(ngModel)]="targetTypes.name" placeholder="Enter Target Type Name" autocomplete="off">
                    <!-- <div class="text-value">{{targetTypes.name}}</div> -->
                    <!-- <ngx-select
                      [allowClear]="true"
                      name="exceptionDetailsForm.assetGroup"
                      [items]="allCategoryDetails"
                      [(ngModel)]="targetTypes.name"
                      placeholder="Select Target Type">
                    </ngx-select> -->
                  </div>
                  <div class="col-md-6 mb-4"></div>
                  <div class="col-md-4 mx-auto my-4 text-center pe-5 createsubmit">
                    <a class="btn btn-border me-2">
                      <button
                        class="submit-btn"
                        type="button"
                        (click)="navigateBack()">
                        <i class="bi bi-x fs-5"></i>
                        Cancel
                      </button>
                    </a>
                    <a
                      class="btn btn-border me-2 btn-green"
                      *ngIf="isCreate">
                      <button
                        class="submit-btn text-white"
                        (click)="createTargetType(targetTypes)"
                        [disabled]="targetTypes.name==='' || targetTypes.desc==='' || targetTypes.domain.length==0 || targetTypes.category.length==0 || targetTypes.config===''">
                        <i class="bi bi-plus fs-5"></i>
                        Create
                      </button>
                    </a>
                    <a
                      class="btn btn-border me-2 btn-green"
                      *ngIf="!isCreate">
                      <button
                        class="submit-btn text-white"
                        (click)="updateTargetType(targetTypes)"
                        [disabled]="targetTypes.name==='' || targetTypes.desc==='' || targetTypes.domain.length==0 || targetTypes.category.length==0 || targetTypes.config===''">
                        <i class="bi bi-check fs-5"></i>
                        Update
                      </button>
                    </a>
                  </div>
                </div>
                <div
                  class="pacman-alert"
                  *ngIf="isTargetTypeCreationUpdationFailed">
                  <div
                    class="sa-icon sa-error animateErrorIcon"
                    style="display: block;">
                    <span class="sa-x-mark animateXMark">
                                                
                      <span class="sa-line sa-left"></span>
                                            
                      <span class="sa-line sa-right"></span>
                                            </span>
                  </div>
                  <div class="pacman-alert-title">{{failedTitle}}</div>
                  <div class="pacman-alert-message">
                    {{loadingContent}}
                    <span class="highlight-pink">{{highlightName}}</span>
                    failed!!!
                  </div>
                  <div class="pacman-alert-button">
                    <a class="btn btn-border">
                      <button
                        class="submit-btn retybutton"
                        type="button"
                        (click)="closeErrorMessage()">
                        <span *ngIf="failedTitle!=='Loading Failed'"><i class="bi bi-x fs-5"></i> Close</span>
                        <span *ngIf="failedTitle==='Loading Failed'"><i class="bi bi-check fs-5"></i> Retry</span>
                      </button>
                    </a>
                  </div>
                </div>
                <div
                  class="pacman-alert"
                  *ngIf="isTargetTypeCreationUpdationSuccess">
                  <div class="sa-icon sa-success animate">
                    <span class="sa-line sa-tip animateSuccessTip"></span>
                    <span class="sa-line sa-long animateSuccessLong"></span>
                    <div class="sa-placeholder"></div>
                    <div class="sa-fix"></div>
                  </div>

                  <div class="pacman-alert-title">{{successTitle}}</div>
                  <div class="pacman-alert-message">
                    Target Type
                    <span class="highlight-pink">{{selectedTargetTypeName}}</span>
                    has been successfully
                    <span *ngIf="isCreate">created</span>
                    <span *ngIf="!isCreate">updated</span>
                    !!!
                  </div>
                  <div class="pacman-alert-button">
                    <!-- <button class="submit-btn btn-border" type="button" (click)="navigateBack()"><i class="fa fa-remove"></i> CLOSE</button> -->
                    <a class="btn btn-border">
                      <button
                        class="submit-btn succesbut"
                        type="button"
                        (click)="navigateBack()">
                        <i class="bi bi-x"></i>
                        Close
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
