import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AssetsComponent } from "../pacman-features/modules/assets/assets.component";
import { ComplianceComponent } from "../pacman-features/modules/compliance/compliance.component";
import { PageNotFoundComponent } from "../shared/components/page-not-found/page-not-found.component";
import {AuthGuardService} from "../shared/services/auth-guard.service";
import { ChangeDefaultAssetGroupComponent } from "./change-default-asset-group/change-default-asset-group.component";
import { PostLoginAppComponent } from "./post-login-app.component";

const routes: Routes = [
  {
    path: 'compliance',
    component: PostLoginAppComponent,
    children: [
      {
        path: 'compliance',
        loadChildren: () =>
          import(
            `../pacman-features/modules/compliance/compliance.module`
          ).then((module) => module.ComplianceModule),
      },
      {
        path: 'assets',
        loadChildren: () =>
          import(`../pacman-features/modules/assets/assets.module`).then(
            (module) => module.AssetsModule
          ),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import(`../pacman-features/modules/admin/admin.module`).then(
            (module) => module.AdminModule
          ),
      },
      {
        path: 'grc',
        loadChildren: () =>
          import(`../pacman-features/modules/grc/grc.module`).then(
            (module) => module.GrcModule
          ),
      },
      {
        path: 'change-default-asset-group',
        component: ChangeDefaultAssetGroupComponent,
        outlet: 'modal'
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostLoginAppRoutingModule {}