<!-- OverallCompliance - Policy Violation - Vulnerabilities  boxes start  -->
<section>
  <div class="container-fluid mt-1">
    <div class="row">
      <div class="col-lg-5 col-md-12 col-12 over-compliance-box">
        <app-overall-compliance></app-overall-compliance>
      </div>
      <div class="col-lg-5 col-md-12 policy-violation-box">
        <app-pacman-issues></app-pacman-issues>
      </div>
      <div class="col-lg-2 col-md-12 vulnerable-tag-box">
        <div class="card">
          <div class="card-body">
            <div class="row row-untagged">
              <!-- <div class="col-5 text-end mb-3 pt-3 px-0">
                <h6>Vulnerabilities</h6>
              </div>
              <div class="col-7 mb-3">
                <div class="box-green">
                  <app-overview-vulnerabilities></app-overview-vulnerabilities>
                </div>
              </div> -->
              <div class="col-5 text-end pt-3"><h6>Untagged</h6></div>
              <div class="col-7">
                <div class="box-green">
                  <!-- 567 <img src="/assets/images/icon-tag.svg" width="27px" /> -->
                  <app-overview-tagging></app-overview-tagging>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- OverallCompliance - Policy Violation - Vulnerabilities  boxes start  -->
