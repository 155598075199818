import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { LoggerService } from '../../../shared/services/logger.service';
import {ICONS} from "../../../shared/constants/icons-mapping";
import {ActivatedRoute, Router} from "@angular/router";
import {AwsResourceTypeSelectionService} from "../../services/aws-resource-type-selection.service";

@Component({
  selector: 'app-view-all-resources',
  templateUrl: './view-all-resources.component.html',
  styleUrls: ['./view-all-resources.component.css']
})
export class ViewAllResourcesComponent implements OnInit, OnDestroy {

   awsResources: any = [];
   activeTileIndex: any = 0;
   categories :any = [];
   categoryNames :any= [];
   filteredResources: any = [];
   selectedResource: any;
   activeFilterCategory: any;
   isViewMore: boolean = false;
   @Output() triggerEvent = new EventEmitter<any>();


  private resourceTypeSelectionSubscription: Subscription |any;

  awsTileClicked (resource :any, index :any) {
    this.activeTileIndex = index;
    this.selectedResource = resource;
  }

  saveSelectedResource() {
    this.awsResourceTypeSelectionService.awsResourceSelected(this.selectedResource);
  }

  filterByCategory(category :any) {
    this.activeFilterCategory = category;
    const resources = this.awsResources.slice();
    this.filteredResources = [];
    resources.forEach((element:any) => {
      if (element.category === category.name || category.name === 'All') {
        this.filteredResources.push(element);
      }
    });
  }

  getAllCategories() {
    this.categories = [];

    // Temporary array to store unique categories
    this.categoryNames = [];

    this.awsResources.forEach((resource:any) => {
      const category = ICONS.categories[resource.category] === undefined ? 'Extra' : resource['category'];
      resource.category = category;   // Update the category of current resource depending on the result of the above line
      if (this.categoryNames.indexOf(category) === -1) {
        this.categoryNames.push(category);
        const obj = {
          'name'   : category,
          'color'  : ICONS.categories[category]
        };
        this.categories.push(obj);
      }
    });

    // If extra categories are present, push them to the end
    if (this.categoryNames.indexOf('Extra') > -1) {
        const extraCategory = this.categories.splice(this.categoryNames.indexOf('Extra'), 1)[0];
        this.categories.push(extraCategory);
    }

    // Push 'All' type at the front if categories array
    this.categories.unshift({
      'name' : 'All',
      'color': '#333333'
    });

    this.activeFilterCategory = this.categories[0];
  }

  addIconsToResources() {
    this.awsResources.forEach((resource:any) => {
      const name = this.categoryNames.indexOf(resource.category) > -1 ? resource.type : 'Extra';
      resource['iconPath'] = ICONS.awsResources[name];
    });
  }

  getAwsResources() {
    if (this.resourceTypeSelectionSubscription) { this.resourceTypeSelectionSubscription.unsubscribe(); }
    this.resourceTypeSelectionSubscription = this.awsResourceTypeSelectionService.getAllAwsResources().subscribe(
      (allAwsResources:any) => {
        this.filteredResources = [];
        this.awsResources = allAwsResources;
        this.getAllCategories();
        this.addIconsToResources();
        this.filteredResources = this.awsResources.slice();
      },
      (error:any) => {
      }
    );
  }
  triggerGraph(resourcetype:string){
    this.triggerEvent.emit(resourcetype);
  }



  constructor(
              private router: Router, private logger: LoggerService,
              private awsResourceTypeSelectionService: AwsResourceTypeSelectionService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
      this.getAwsResources();
  }

  viewmore(view:any) {
    if (view === 'viewmore') {
      this.isViewMore = true
    } else {
      this.isViewMore = false
    }
  }

  ngOnDestroy() {
    try {
      this.resourceTypeSelectionSubscription.unsubscribe();
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }

}
